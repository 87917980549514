<template>
  <div id="positionSignin">
    <van-nav-bar title="上门签到" left-arrow @click-left="goLastPage" :border="false" />
    <div class="filpWrap">
      <p class="filpTitle">距离上门签到还剩</p>
      <TimeFilpDown :endDate="endDate" :timeUnit="timeUnit" />
      <p class="serviceTime">上门服务时间为： <span v-if="show">{{reservationOrder.data.reservation.visit_time}}</span></p>
    </div>
    <!-- <div class="positionWrap">
      <div class="row">
        <span>
          <svg t="1603950255592" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4639" width="22" height="22">
            <path d="M516.320553 65.913723C327.473365 66.759049 174.512778 221.085162 175.350878 409.954025c0.787526 177.21493 282.974557 496.596289 315.154731 532.417865a39.737529 39.737529 0 0 0 59.440119-0.2601c31.862273-36.110576 311.202653-357.991787 310.415127-535.213942-0.838101-188.861638-155.178664-341.822225-344.040302-340.984125z m2.196402 492.860961c-76.483906 0.339575-139.009102-61.614845-139.348677-138.105976-0.339575-76.491131 61.629295-139.001877 138.105976-139.334227 76.512806-0.3468 139.023552 61.60762 139.363127 138.091526 0.339575 76.498356-61.614845 139.001877-138.120426 139.348677z" fill="#FF816C" p-id="4640"></path>
          </svg>
        </span>
        <span class="publicFont">
          签到地址:&nbsp;&nbsp;{{signinAdress}}
        </span>
      </div>
      <div class="row">
        <span>
          <svg t="1603951477144" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9214" width="22" height="22">
            <path d="M 512 136.533 C 303.787 136.533 136.533 303.787 136.533 512 s 167.253 375.467 375.467 375.467 s 375.467 -167.253 375.467 -375.467 S 720.213 136.533 512 136.533 Z m 170.667 409.6 h -170.667 c -20.48 0 -34.1333 -13.6533 -34.1333 -34.1333 V 273.067 c 0 -20.48 13.6533 -34.1333 34.1333 -34.1333 s 34.1333 13.6533 34.1333 34.1333 v 204.8 h 136.533 c 20.48 0 34.1333 13.6533 34.1333 34.1333 s -13.6533 34.1333 -34.1333 34.1333 Z" p-id="9215" fill="#FF816C"></path>
          </svg>
        </span>
        <span class="publicFont">签到时间:&nbsp;&nbsp;{{signinTime}}</span>
      </div>
    </div> -->
    <div class="locationWrap">
      <p class="title">
        <svg class="icon icon-reset-location" aria-hidden="true">
          <use xlink:href="#icon-locationfill"></use>
        </svg>
        签到地址:
      </p>
      <p class="text" v-if="signinAdress != undefined">{{signinAdress}}</p>
      <p class="text" v-else>
        <van-loading size="24px">定位中...</van-loading>
      </p>
    </div>
    <div class="locationWrap">
      <p class="title">
        <svg class="icon icon-reset-location" aria-hidden="true">
          <use xlink:href="#icon-timefill"></use>
        </svg>
        签到时间:
      </p>
      <p class="text">{{signinTime}}</p>
    </div>
    <address-location @location="getAddressLocation" />
    <div class="positionBottom">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitAdress">
        下一步
      </van-button>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import TimeFilpDown from "@/components/effect/TimeFilpDown.vue";
import AddressLocation from "@/components/public/AddressLocation.vue";
export default {
  data() {
    return {
      endDate: "",
      time: "",
      timeUnit: ["天", "时", "分", "秒"],
      reservationOrder: {},
      show: false,
      signinTime: "",
      signinAdress: undefined,
      timer: "",
    };
  },
  components: {
    TimeFilpDown,
    TopBar,
    AddressLocation,
  },
  created() {
    this.getVisitTime();
    this.getNowTime();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getNowTime() {
      let dd = new Date();
      this.timer = setTimeout(this.getNowTime, 1000);
      this.signinTime = dd.toLocaleString();
    },
    getVisitTime() {
      this.$http
        .get(`/api/v2/worker/order/${this.$route.params.orderId}/reservation`)
        .then((res) => {
          console.log(res);
          this.reservationOrder = res;
          let serviceTime = res.data.reservation.visit_time
            .replace("月", "/")
            .replace("日", "")
            .substring(0, 16);
          let dd = new Date();
          this.endDate = Date.parse(new Date(serviceTime));
          this.show = true;
        });
    },
    submitAdress() {
      this.$http
        .post(`/api/v2/worker/order/${this.$route.params.orderId}/clock_in`, {
          address: this.signinAdress,
        })
        .then((res) => {
          this.goSubPage("inspection", this.$route.params.orderId);
        })
        .catch((err) => {
          this.$toast(err.error);
          if (err.code == 1002) {
            this.goSubPage("inspection", this.$route.params.orderId);
          }
        });
    },
    getAddressLocation(e) {
      console.log("接收过来的定位信息", e);
      let _add = e.addressComponent;
      this.signinAdress =
        _add.province +
        _add.city +
        _add.district +
        _add.township +
        _add.street +
        _add.streetNumber;
    },
  },
};
</script>
<style lang="less" scoped>
#positionSignin {
  .filpWrap {
    padding: 2rem 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 93%;
    margin: 2rem auto 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #eaeaea;
    p {
      padding: 2rem 0;
      &.filpTitle {
        font-size: 2rem;
        text-align: center;
        color: #232323;
      }
      &.serviceTime {
        font-size: 1.4rem;
        color: #b0b0b0;
      }
    }
  }
  .positionWrap {
    width: 93%;
    width: 93%;
    margin: 2rem auto 2rem;
    background: #fff;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border: 2px solid #ddd;
    border-radius: 10px;
    border-image: -webkit-linear-gradient(orange, #ff816c) 30 30;
    border-image: -moz-linear-gradient(orange, #ff816c) 30 30;
    border-image: linear-gradient(orange, #ff816c) 30 30;
    .row {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      padding: 1rem 0;
      .publicFont {
        font-size: 1.4rem;
        text-indent: 1rem;
      }
    }
  }
  .locationWrap {
    border-radius: 10px;
    width: 93%;
    margin: 2rem auto 2rem;
    box-sizing: border-box;
    background: #fff;
    padding: 1rem 2rem;
    font-size: @theme-font-size-m;
    p {
      padding: 0.5rem 0;
      &.title {
        font-size: @theme-font-size-l;
        .icon-reset-location {
          width: 2.5rem;
          height: 2.5rem;
          color: #6db6ff;
        }
      }
      &.text {
        color: @themeFontColor;
        text-indent: 1rem;
      }
    }
  }
  .positionBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .insurance {
    width: 100%;
    height: 5rem;
    background: #fff;
    font-size: 1.6rem;
    text-align: center;
    line-height: 5rem;
  }
}
</style>